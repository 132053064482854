import { requestStatusDesc } from "../enums/requestState";
import { consultar } from "../services/variablesconcepto";

export default async function getFields(type) {
  let concepto = await consultar.fetch();
  let fields = {
    json_fields: {
      "Fecha del Procedimiento": "updated_at",
      "Permisionario": "owner_permit",
      "Consecutivo": "consecutive",
      "Embarcaciones": { field: "boats", callback: (value) => {
        let boatDesc = "";
        value.forEach((bote) => {
          boatDesc += bote.boat_desc;
        })
        return boatDesc;
      }}, 
      "Bandera": { field: "boats", callback: (value) => {
          return concepto.items_select_current_state_pavilion.find(
              (x) => x.abbr == value[0].current_state_pavilion
            ) != undefined
          ? concepto.items_select_current_state_pavilion.find(
              (x) => x.abbr == value[0].current_state_pavilion
            ).state
          : "-"
      } },
      "Eslora (m)" : {field: "boats", callback: (value) => {return value[0].length_overall}},
      "Calado/Proyecto(m)": {field: "boats", callback: (value) => {return value[0].calado}},
      "Manga (m)": {field: "boats", callback: (value) => {return value[0].beam_extreme_breadth}},
      "Tipo de barco" : {field: "boats", callback:(value) => {
          return value[0].boat_type_state != null
              ? value[0].boat_type_state
              : value[0].boat_type != null
              ? value[0].boat_type.nombre
              : "-"
      }},
      "Registro Bruto (TRB)": {field: "boats", callback: (value) => { return value[0].gross_registered_tonnage}},
      "Regitro Neto (TRN)": {field: "boats", callback: (value) => { return value[0].net_registered_tonnage}},
      "No. de Tripulantes" : {field: "boats", callback: (value) => { return value[0].number_of_crew}},
      "Potencia del Motor" : {field: "boats", callback: (value) => { return value[0].main_engines_power}},
      "No. de Matrícula": {field: "boats", callback: (value) => { return value[0].registration_number}},
      "No. de Matrícula Expedida Por": {field: "boats", callback: (value) => { return value[0].expedition_by}},

      "Tipo de permiso": {field: "boats", callback: () => { return type == "art" ? "Artesanal" : "Industrial"}},
      "Lugar solicitud": "lugar.nombre", 
      "Fecha de expedición": "boat_unique_on_request.date_expedition_patent",
      "Vigencia (meses)": "boat_unique_on_request.validity_of_patent",
      "Fecha inicio": "boat_unique_on_request.expedition_date",
      "Fecha vencimiento": "boat_unique_on_request.date_expiration_patent",
      "Valor Derechos Aplicables (pesos)": {field: "valor_tasa_anual", callback: (value) => {  
          let varvalue = value == 'N/A' || value == '' ? 0 : value;
          return Intl.NumberFormat('es-CO',{style:'currency',currency:'COP'}).format(Number(varvalue));
        }
      },
      "Tipo de pesquería": {field: "boat_unique_on_request", callback: (value) => {  return value.type_fishery.map((x) => x.pivot.description) }},
      "Artes de pesca": {field: "boat_unique_on_request", callback: (value) => {  return value.fishing_arts.map((x) => x.pivot.description) }},
      "Area de Operaciones": {field: "boat_unique_on_request", callback: (value) => {   return value.fishing_zone != null ? value.fishing_zone : "-" }},
      "Estado": {field:"status", callback: (value) => {
        return {
          completed: requestStatusDesc.COMPLETED,
          process: requestStatusDesc.PROCESS,
          acepted: requestStatusDesc.ACEPTED,
          refused: requestStatusDesc.REFUSED,
          canceled: requestStatusDesc.CANCELED
        }[value];
      }}, 
    }
  };
  return fields;
}

