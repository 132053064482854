var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.dialogSee)?_c('ComponentModalInfoGeneral',{attrs:{"modal":_vm.dialogSee,"download":_vm.download,"dataModal":_vm.dataParent,"title":_vm.title},on:{"handlePDF":_vm.generatePDF,"handleClose":function($event){_vm.dialogSee = false;
      _vm.dataParent = [];}}}):_vm._e(),_c('v-card',{staticClass:"mx-auto"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
        'items-per-page-options': [5,10,15]
      },"headers":_vm.headers,"items":_vm.filtrado.data,"search":_vm.searchRequest,"server-items-length":_vm.filtrado.total,"page":_vm.filtrado.current_page,"items-per-page":_vm.perPage},on:{"update:options":_vm.onPaginate},scopedSlots:_vm._u([{key:"item.action",fn:function({ item }){return [_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[((item.estado > 0))?[(_vm.type == 'art' 
              ? _vm.$can('verDetalleDeProcedimientoArtesanales')
              : _vm.$can('verDetalleDeProcedimientoIndustriales'))?_c('v-list-item',{on:{"click":function($event){return _vm.seeItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("remove_red_eye")]),_vm._v(" Detalle ")],1)],1):_vm._e()]:_vm._e()],2)],1)]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-menu',[_c('v-list',[_c('v-list-item',{staticStyle:{"cursor":"pointer"}},[_c('v-list-item-content',{on:{"click":function($event){return _vm.setFilterRequestType(null, $event)}}},[_c('v-list-item-title',[_vm._v("Tipo de solicitud")])],1)],1),_vm._l((_vm.itemsRequestTypes),function(item,index){return _c('v-list-item',{key:index,staticStyle:{"cursor":"pointer"}},[_c('v-list-item-content',{on:{"click":function($event){return _vm.setFilterRequestType(item, $event)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.nombre))])],1)],1)})],2)],1),_c('export-excel',{staticStyle:{"ml-4 margin-bottom":"10px"},attrs:{"fetch":_vm.exportBoats,"before-generate":_vm.startDownload,"before-finish":_vm.finishDownload,"fields":_vm.json_fields,"worksheet":"Patentes","name":"Patentes.xls"},on:{"update:fetch":function($event){_vm.exportBoats=$event}}},[(_vm.type == 'ind' 
              ? _vm.$can('exportarExcelIndustriales')
              : _vm.$can('exportarExcelArtesanales')
              )?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"green darken-4 ml-4 white--text","small":"","dark":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fa fa-file-excel")]),_vm._v(" Exportar ")],1):_vm._e()],1),_c('v-spacer'),_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:200ms",value:(_vm.search),expression:"search",arg:"200ms"}],attrs:{"append-icon":"search","label":"Busqueda","single-line":"","hide-details":""},model:{value:(_vm.searchRequest),callback:function ($$v) {_vm.searchRequest=$$v},expression:"searchRequest"}})],1)]},proxy:true},{key:"item.status",fn:function({ item }){return [_c('v-chip',{attrs:{"dark":"","color":_vm.getColorByStatus(item.status)}},[_vm._v(_vm._s(_vm.getStatus(item.status)))])]}},{key:"item.estadoTramite",fn:function({ item }){return [(item.estadoTramite != 'Tramitado')?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(item.estadoTramite == 'Vencido')?[_c('v-chip',{attrs:{"dark":"","color":"red"}},[_vm._v(_vm._s(item.estadoTramite))])]:_vm._e(),(item.estado >= 2)?[_c('v-chip',{attrs:{"dark":"","color":"#3845c6"}},[_vm._v(_vm._s(item.estadoTramite))])]:_vm._e(),(item.estadoTramite == 'En espera' || item.estadoTramite == 'Sin fecha trámite')?[_c('v-chip',{attrs:{"dark":"","color":"#3845c6"}},[_vm._v(_vm._s(item.estadoTramite))])]:_vm._e(),(Number.isInteger(item.estadoTramite))?[_c('v-chip',{attrs:{"dark":"","color":"orange"}},[_vm._v(_vm._s(item.estadoTramite)+" días")])]:_vm._e()],2)]}}],null,true)},[_c('span',[_vm._v("Vence en: "+_vm._s(item.expiresIn))])])]:[_c('v-chip',{attrs:{"dark":"","color":"#3845c6"}},[_vm._v(_vm._s(item.estadoTramite))])]]}},{key:"item.boats",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getBoatsDesc(item))+" ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }