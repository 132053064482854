<template>
  <div>
    <v-card class="mx-auto">
      <v-card-title class="headline">
        {{
          type == "art"
            ? "Patentes artesanales registradas"
            : "Patentes industriales registradas"
        }}
      </v-card-title>
      <PatentesTable ref="patentesTable" :type="type" :headers="headers"></PatentesTable>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PatentesTable from './PatentesTable.vue';

export default {
  components: { PatentesTable },
  props: ["type"],
  data: () => ({
    headers: [
      {
        text: "Fecha del Procedimiento",
        value: "updated_at",
        sortable: true,
      },
      {
        text: "Permisionario",
        align: "left",
        value: "owner_permit",
      },
      { text: "Consecutivo", value: "consecutive", sortable: false },
      { text: "Embarcaciones", value: "boats", sortable: false },
      { text: "Estado", value: "status", sortable: false },
      { text: "Acciones", value: "action", sortable: false },
    ],
    //empieza mapas
  }),
  computed: {
    ...mapGetters([
      "allBoats",
    ]),
  },
  methods: {
    
  },
};
</script>
